import * as React from 'react';

import { SyntheticEvent, useState } from 'react';

import { useForm } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';

import { Describe, object, string, nonempty } from 'superstruct';
import { getAttestation } from '../api/attestation';
import { AttestationRequest } from '../../functions/api/attestation';

const schema: Describe<AttestationRequest> = object({
  attestation: nonempty(string()),
});

function onPromise<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent) => {
    if (promise) {
      promise(event).catch((error) => {
        console.log('Unexpected error', error);
      });
    }
  };
}

const Form: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AttestationRequest>({ resolver: superstructResolver(schema) });

  const [proof, setProof] = useState<string>();
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

  const onSubmit = handleSubmit(
    async (attestationRequest: AttestationRequest) => {
      setAwaitingResponse(true);
      try {
        const maybeProof = await getAttestation(attestationRequest);
        setProof(JSON.stringify(maybeProof));
      } catch {
        setProof('An unknown error has occurred. Please try again.');
      }
      setAwaitingResponse(false);
    }
  );

  return (
    <div className="flex flex-col flex-grow lg:max-w-lg space-y-8">
      <form onSubmit={onPromise(onSubmit)}>
        <textarea
          className="w-full shadow-sm  focus:ring-indigo-500
          focus:border-indigo-500 sm:text-sm border border-gray-300
          rounded-md"
          rows={5}
          {...register('attestation', { required: true })}
        />
        {errors.attestation && (
          <div className="flex justify-center">
            <span className="text-white">This field is required</span>
          </div>
        )}

        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={awaitingResponse}
              className="ml-3 inline-flex justify-center py-2 px-4 border
            border-transparent shadow-sm text-sm font-medium rounded-md
            text-white bg-indigo-600 hover:bg-indigo-700
            focus:outline-none focus:ring-2 focus:ring-offset-2
            focus:ring-indigo-500"
            >
              Prove
            </button>
          </div>
        </div>
      </form>

      {proof && (
        <div>
          <textarea
            className="w-full shadow-sm  focus:ring-indigo-500
          focus:border-indigo-500 sm:text-sm rounded-md"
            rows={5}
            value={proof}
            disabled={true}
          />
          <div className="flex justify-center">
            <span className="text-white">Your Portsona proof</span>
          </div>
        </div>
      )}
    </div>
  );
};

const Index: React.FC = () => {
  return (
    <div className="bg-white">
      <main>
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Portsona</span>
                  <span className="block text-indigo-200 text-3xl">
                    prove reputation without revealing identity
                  </span>
                </h1>
                <div className="mt-10 max-w-4xl mx-auto sm:max-w-none sm:flex justify-center">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Index;
