import {
  AttestationRequest,
  AttestationResponse,
  ErrorResponse,
} from '../../functions/api/attestation';

export const getAttestation = async (
  attestationRequest: AttestationRequest
): Promise<AttestationResponse | ErrorResponse> => {
  try {
    const response = await fetch(
      `${process.env.GATSBY_API_URL || ''}attestation`,
      {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(attestationRequest),
      }
    );
    if (response.ok) {
      return response.json();
    }
  } catch {
    const fetchError: ErrorResponse = {
      type: 'api',
      code: 'endpoint_unreachable',
    };
    return Promise.resolve(fetchError);
  }
  const browserError: ErrorResponse = {
    type: 'browser',
    code: 'fetch_error',
  };
  return Promise.resolve(browserError);
};
